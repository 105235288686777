html, body {
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 12pt;
}

h1, h2 {
  font-family: 'ProximaNova-Extrabld', sans-serif;
}

h3, h4, h5 {
  font-family: 'ProximaNova-Bold', sans-serif;
}

.light {
  font-family: 'ProximaNova-Light';
}

.light-blue {
  color: #8383A0;
}

.medium {
  font-family: 'ProximaNova-Medium';
}

.bold {
  font-family: 'ProximaNova-Bold';
}

.semibold {
  font-family: 'ProximaNova-Semibold';
}

@keyframes inanimate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-exit-active {
  display: none;
}

.fade-enter-active {
  animation: inanimate 200ms;
}

.background {
  width: 100%;
  background-color: #fbfbfb;
  padding-bottom: 40px;
  min-height: 100vh;
}

.foreground {
  color: #000160;
  background-color: white;
  box-shadow: 0px 5px 8px 2px #ccc;
  border-radius: 10px;
  padding-bottom: 20px;
}

@media screen and (max-width: 959px) {
  
  html, body {
    font-size: 12pt;
  }

  .foreground {
    background-color: #fbfbfb;
    box-shadow: none;
  }
  .background {
    height: 100vh;
  }
}

/*
.transition-group {}
.fade-enter {}
.fade-enter-active {}
.fade-enter-done {}
.fade-exit {}
.fade-exit-active {}
*/
