
.profile {
  text-align: right;
}

.flexContainer {
  padding: 20px 0
}

.logo a,
.profile a {
  color: #000059;
}

.closeMobile,
.closeDesktop {
  display: none;
}

.closeState .closeMobile {
  display: none;
}

.closeState .closeDesktop {

  display: block;
}

@media screen and (max-width: 959px) {
  .closeState .closeMobile {
    display: block;
  }
  .closeState .closeDesktop {

    display: none;
  }
}