body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.button {
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 1em;
  background-color: inherit;
  border-radius: 5px;
}

.error {
  color: red;
}
